import { useState, useEffect, useRef } from "react";
import { withStoreProvider } from '@remyar/react-store';
import { injectIntl } from 'react-intl';
import { DataGridPremium } from './x-data-grid-premium';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import NoteAddIcon from '@mui/icons-material/NoteAdd';

import utils from '../../utils';


function MyDataGrid(props) {
    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);
    const [contextMenu, setContextMenu] = useState({ visible: false, position: undefined, card: undefined });
    const rows = props.rows.map((row, idx) => {return { ...row, id: idx };}) || []

    let headers = props.headers || [];

    headers = headers.map((header, idx) => {
        return ({
            field: header.id,
            headerName: header.label,
            renderCell: (params) => {
                let row = props.rows[params.id];
                return header.render && header.render(row)
            },
            flex: header.flex,
            resizable: false,
            width: header.width,
            minWidth: header.minWidth
        })
    })

    useEffect(() => {
        const rect = elementRef?.current?.getBoundingClientRect();
        setHeight(window.innerHeight - rect?.y - 24 - 16);
    }, [])

    const handleContextMenu = (event) => {
        event.preventDefault();
        let rowIndex = rows.findIndex((row) => {
            //console.log(Number(event.currentTarget.getAttribute('data-id')))
            return row.id === Number(event.currentTarget.getAttribute('data-id'));
        });

        const newRows = props.rows[rowIndex];

        setContextMenu({
            visible: true, position: { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }, card: newRows
        }
        );
    }

    const handleClose = (event, item) => {
        setContextMenu({ ...contextMenu, visible: false });
    };

    return <Box sx={{ height: height, width: '100%', overflow: "hidden", minWidth: 0 }} ref={elementRef}>
        <DataGridPremium
            disableVirtualization={props.disableVirtualization || false}
            useResizeContainer
            pageSizeOptions={[rows.length]}
            disableRowSelectionOnClick
            disableColumnMenu
            cellSelection={false}
            columnHeaderHeight={34}
            rowHeight={34}
            rows={rows}
            columns={headers}
            onRowDoubleClick={(event)=>{
                let row = props.rows[event.id];
                props.onRowDoubleClick && props.onRowDoubleClick(row);
            }}
            onRowClick={(item , event)=>{
                let row = props.rows[item.id];
                props.onRowClick && props.onRowClick(event , row);
            }}
            onCellClick={(params , event ) => {
                let row = props.rows[params.id];
                props.onCellClick && props.onCellClick(event, params , row);
            }}
            slotProps={{
                row: {
                    onContextMenu: handleContextMenu,
                    style: { cursor: 'context-menu' },
                },
                cell: {
                    style: { outline: 'none' }
                }
            }}

        />

        {props.contexMenu && <Menu
            open={contextMenu.visible}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu.position != null
                    ? { top: contextMenu?.position?.mouseY, left: contextMenu?.position?.mouseX }
                    : undefined
            }
            slotProps={{
                root: {
                    onContextMenu: (event) => {
                        event.preventDefault();
                        handleClose();
                    },
                },
            }}
        >
            {props.contexMenu && props.contexMenu(contextMenu?.card, handleClose)}
        </Menu>}
    </Box>

}

export default withStoreProvider(injectIntl(MyDataGrid));