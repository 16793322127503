import { injectIntl } from 'react-intl';
import { withStoreProvider } from '@remyar/react-store';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';

import utils from '../../utils';
import MtgSvg from '../mtgSvg';

function BlockCard(props) {

    let block = props.expansion;
    let collection = props.collection || [];

    collection = collection.filter((e) => e.set_code === block.code);

    let finalCollection = [];

    collection.forEach((c) => {
        if (finalCollection.find((e) => e.card_id === c.card_id) === undefined) {
            finalCollection.push(c);
        }
    })

    return <Card sx={{ height: "128px", cursor: "pointer", width: "100%" }} onClick={props.onClick ? props.onClick : () => { }}>
        <Grid container>
            <Grid size={2} sx={{ alignContent: 'center', }}>
                <Box sx={{ width: "40px", height: "40px", alignContent: "center", paddingLeft: "12px" }}>
                    {block.code && <MtgSvg
                        svgName={"svg_" + block.code}
                    />}
                </Box>
            </Grid>
            <Grid size={10}>
                <CardContent>
                    <Tooltip title={utils.ExpansionLocalise.getTranslationName(block, props.globalState.settings)}>
                        <Typography component="div" variant="h6" noWrap>
                            <b> {utils.ExpansionLocalise.getTranslationName(block, props.globalState.settings)}</b>
                        </Typography>
                    </Tooltip>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ color: 'text.secondary' }}
                    >
                        {block.releaseDate}
                    </Typography>
                </CardContent>

            </Grid>
        </Grid>
        <CardActions>
            <Box sx={{ position: 'relative', display: 'inline-flex', width: "100%" }}>
                <LinearProgress variant="determinate" value={(finalCollection.length / block?.card_count) * 100} sx={{ borderRadius: 5, height: 14, width: "100%" }} />
                <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography component="div" variant="body2" color="textSecondary">{finalCollection.length + " / " + block?.card_count}</Typography>
                </Box>
            </Box>
        </CardActions>

    </Card>
}

export default withStoreProvider(injectIntl(BlockCard));