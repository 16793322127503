import { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import { withNavigation } from '@remyar/react-navigation';

import localeData from '../../locales';
import Loader from '../../components/loader';
import ProgressBarModal from '../../components/progressBarModal';

import actions from '../../actions';

import locales from '../../locales';

function SettingsPage(props) {

    const user = props.globalState.user || {};
    const [displayLoader, setDisplayLoader] = useState(false);
    const [progressBar, setProgressBar] = useState({ visible: false });


    const inputRef = useRef(null);
    const intl = props.intl;


    async function _processImport(json) {
        try {

            let _import = 0;
            let totalImport = Object.keys(json.Count).length;

            for (let cardId in json.Count) {

                let cards = (await props.dispatch(actions.cards.getByMultiverseId(cardId)))?.cards || [];

                setProgressBar({ visible: true, title: "Import : " + _import + " / " + totalImport, value: (_import / totalImport) * 100 });
             
                for (let count = 0; count < json.Count[cardId]; count++) {
                    _import++;
                    for (let _card of cards) {
                        let card = {};
                        card.uuid = _card.uuid;
                        card.setCode = _card.setCode;

                        if (json.Languages[cardId]?.includes("Fran")) {
                            card.language = "French";

                            if (json.Tags[cardId]?.toUpperCase().includes("EN")) {
                                card.language = "English";
                            }
                        }
                        card.note = "";
                        card.isFoil = false;
                        if (json.Tags[cardId]?.toUpperCase().includes("foil")) {
                            card.isFoil = true;
                        }
                        
                        await props.dispatch(actions.collection.add(card, card.language, card.isFoil, ""));
                    }
                }
            }

        } catch (err) {
            props.snackbar.error(intl.formatMessage({ id: err.message }));
        } finally {
            setProgressBar({ visible: false });
            setDisplayLoader(false);
        }
    }

    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        const reader = new FileReader();

        reader.onload = async function (e) {
            const text = e.target.result;
            _processImport(JSON.parse(text));
        };

        reader.onerror = (err) => {
            console.error(err);
        }

        reader.readAsText(fileObj);
    }

    return <Box>

        <Loader display={displayLoader} />
        <ProgressBarModal
            display={progressBar.visible}
            title={progressBar.title || ""}
            value={progressBar.value || 0}
        />

        <Typography variant="h6" component="h2">
            {props.intl.formatMessage({ id: "SettingsPage.Localization" })}
        </Typography>
        <Divider />
        <List>
            <ListItem
                secondaryAction={
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            value={props.globalState?.user?.locale}
                            onChange={async (event) => { 
                                locales.setLocale(event.target.value);
                                await props.dispatch(actions.user.setSettings({ locale: event.target.value }));
                                
                            }}
                            input={<OutlinedInput />}
                        >
                            <MenuItem disabled value="">
                                <em>Language</em>
                            </MenuItem>
                            {localeData.getLanguages().map((name) => {
                                return <MenuItem
                                    key={name}
                                    value={name.code}
                                >
                                    {name.language}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                }
            >
                <ListItemText
                    primary={props.intl.formatMessage({ id: "SettingsPage.Dropdown.Language" }) + " :"}
                />
            </ListItem>
            <ListItem secondaryAction={<Checkbox 
                checked={user.translateInLocale}
                onChange={(event)=>{
                    props.dispatch(actions.user.setSettings({ translateInLocale: event.target.checked }));
                }}
            />}>
                <ListItemText
                    primary={props.intl.formatMessage({ id: "SettingsPage.DisplayCardInLocale" }) + " :"}
                />
            </ListItem>
        </List>
        <Typography variant="h6" component="h2">
            {props.intl.formatMessage({ id: "SettingsPage.Display" })}
        </Typography>
        <Divider />
        <List>
            <ListItem secondaryAction={<Checkbox
                checked={user.translateInLocale}
                onChange={(event) => {
                    props.dispatch(actions.user.setSettings({ languageTooltip: event.target.checked }));
                }}
            />}>
                <ListItemText
                    primary={props.intl.formatMessage({ id: "SettingsPage.DisplaylanguageTooltip" }) + " :"}
                />
            </ListItem>
        </List>
        {/*
        <Typography variant="h6" component="h2">
            {props.intl.formatMessage({ id: "SettingsPage.Import" })}
        </Typography>
        <Divider />
        <List>
            <ListItem
                secondaryAction={<>
                    <input
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type="file"
                        onChange={handleFileChange}
                    />
                    <Button variant="contained" fullWidth onClick={() => {
                        setDisplayLoader(true);
                        // 👇️ open file input box on click of another element
                        inputRef.current.click();
                    }}>
                        {props.intl.formatMessage({ id: "SettingsPage.Import" })}
                    </Button>
                </>
                }
            >
                <ListItemText
                    primary={props.intl.formatMessage({ id: "SettingsPage.Dropdown.Language" }) + " :"}
                />
            </ListItem>
        </List>*/}
    </Box>
}

export default withStoreProvider(withSnackBar(withNavigation(injectIntl(SettingsPage))));