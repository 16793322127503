import utils from '../../utils';

export default {
    get: utils.actions(async ({ extra }) => {
        try {
            let result = await extra.api.method.get("/api/v1/private/collection");
            return { collection: result.collection.filter((e) => e.deleted == undefined || e.deleted == false) }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    add: utils.actions(async (set, card, language, isFoil = false, note = "", { extra, getState }) => {
        try {
            let result = await extra.api.method.post("/api/v1/private/collection", { set_code: set.code, card_id: card.id, language: language, isFoil: isFoil, note: note });
            let collection = getState().collection;
            collection.push(result.add);
            return { collection: collection.filter((e) => e.deleted == undefined || e.deleted == false) }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    addNormal: utils.actions(async (set, card, language, note = "", { extra, getState }) => {
        try {
            
            let result = await extra.api.method.post("/api/v1/private/collection", { set_code: set.code, card_id: card.id, language: language, note: note, isFoil: false });
            let collection = getState().collection;
            collection.push(result.add);
            return { collection: collection.filter((e) => e.deleted == undefined || e.deleted == false) }

        } catch (err) {
            throw { message: err.message };
        }
    }),
    addFoil: utils.actions(async (set, card, language, note = "", { extra, getState }) => {
        try {
            let result = await extra.api.method.post("/api/v1/private/collection", { set_code: set.code, card_id: card.id, language: language, note: note, isFoil: true });
            let collection = getState().collection;
            collection.push(result.add);
            return { collection: collection.filter((e) => e.deleted == undefined || e.deleted == false) }

        } catch (err) {
            throw { message: err.message };
        }
    }),
    remove: utils.actions(async (collection_card_id, { extra }) => {
        try {
            let result = await extra.api.method.del("/api/v1/private/collection", { id: collection_card_id });
            return { collection: result.collection.filter((e) => e.deleted == undefined || e.deleted == false) }
        } catch (err) {
            throw { message: err.message };
        }
    })
}