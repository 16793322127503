import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import Loader from '../../components/loader';
import DataGrid from '../../components/datagrid';
import DisplayImage from '../../components/displayImage';
import CardFilter from '../../components/cardFilter';

import actions from '../../actions';
import utils from '../../utils';

import MtgSvg from '../../components/mtgSvg';
import Router from '../../routes';
import CardContextMenu from '../../components/CardContextMenu';
import RemoveCarddialog from '../../components/removeCarddialog';
import AddCardDialog from '../../components/addCardDialog';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

function CollectionPage(props) {
    const [displayLoader, setDisplayLoader] = useState(false);
    const [collection, setCollection] = useState(props.globalState?.collection || []);
    const [cardsUnFiltered, setCards] = useState([]);
    const [cards, setCardsFiltered] = useState([]);
    const [sets, setSets] = useState([]);
    const [removeCard, setRemoveCard] = useState({ visible: false });
    const [addCard, setAddCard] = useState({ visible: false });

    const [cardPrint, setCardPrint] = useState({ visible: false, position: undefined, card: undefined });
    const [filter, setFilter] = useState("");

    let Language = [
        { label: "en", display: "en", language: "English", disabled: true },
        { label: "es", display: "es", language: "Spanish", disabled: true },
        { label: "fr", display: "fr", language: "French", disabled: true },
        { label: "de", display: "de", language: "German", disabled: true },
        { label: "it", display: "it", language: "Italian", disabled: true },
        { label: "pt", display: "pt", language: "Portuguese (Brazil)", disabled: true },
        { label: "ja", display: "ja", language: "Japanese", disabled: true },
        { label: "ko", display: "ko", language: "Korean", disabled: true },
        { label: "ru", display: "ru", language: "Russian", disabled: true },
        { label: "zhs", display: "汉语", language: "Chinese Simplified", disabled: true },
        { label: "zht", display: "漢語", language: "Chinese Traditional", disabled: true }
    ];

    const headers = [
        {
            id: 'collector_number', label: props.intl.formatMessage({ id: "DataGridCardList.Number" }), width: 75, render: ((card) => {
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {card.collector_number}
                </Box>
            })
        },
        {
            id: 'name', label: props.intl.formatMessage({ id: "DataGridCardList.Name" }), flex: 1, render: ((card) => {
                return <span style={{ display: "block", cursor: "pointer" }}>{utils.CardLocalise.getTranslationName(card, props.globalState.user)}</span>
            })
        },
        {
            id: 'mana_cost', label: props.intl.formatMessage({ id: "DataGridCardList.Color" }), minWidth: 100, render: ((card) => {

                let manaCost = card?.mana_cost?.replaceAll('{', '').replaceAll('/', '').split('}').filter(e => e.length > 0);
                return <Box sx={{ paddingTop: "4px" }}>
                    {manaCost?.map((mc) => {
                        return <MtgSvg
                            svgName={"svg_" + mc}
                            width="18px" />
                    })}
                </Box>
            })
        },
        {
            id: 'setName', label: props.intl.formatMessage({ id: "Header.Set.Title" }), minWidth: 150, render: ((card) => {
                let set = sets.find((e) => e.code == (collection?.find((c) => c.card_id == card.id))?.set_code);
                return <Tooltip title={utils.ExpansionLocalise.getTranslationName(set, props.globalState.user)}>
                    <div style={{ display: "block", cursor: "pointer" }}>{utils.ExpansionLocalise.getTranslationName(set, props.globalState.user)}</div>
                </Tooltip>
            })
        },
        {
            id: 'rarity', label: props.intl.formatMessage({ id: "DataGridCardList.Rarity" }), width: 64, render: ((card) => {
                let set = sets.find((e) => e.code == (collection?.find((c) => c.card_id == card.id))?.set_code);
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center", paddingTop: "4px" }}>
                    <MtgSvg
                        svgName={"svg_" + set?.code}
                        class={"rarity_" + card?.rarity}
                        height="24px" />
                </Box>
            })
        },
        {
            id: 'count', label: props.intl.formatMessage({ id: "DataGridCardList.Collection" }), render: (card) => {
                let count = collection.filter((e) => ((e.card_id == card.id) && (e.isFoil == false)));
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {count.length > 0 ? count.length : ''}
                </Box>
            }
        },
        {
            id: 'price', label: props.intl.formatMessage({ id: "DataGridCardList.AveragePrice" }), render: (card) => {
                let price = 0;
                if (card?.prices?.eur != undefined) {
                    price += parseFloat(card?.prices?.eur.toString());
                }
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {(price || 0).toFixed(2) + " €"}
                </Box>

            }
        },
        {
            id: 'foilCount', label: props.intl.formatMessage({ id: "DataGridCardList.Foils" }), render: (card) => {
                let count = collection.filter((e) => ((e.uuid == card.uuid) && (e.isFoil == true)));
                return count.length > 0 ? count.length : '';
            }
        },
        {
            id: 'foilPrice', label: props.intl.formatMessage({ id: "DataGridCardList.AverageFoilPrice" }), render: (card) => {
                let price = 0;
                if (card?.prices?.eur_foil != undefined) {
                    price += parseFloat(card?.prices?.eur_foil.toString());
                }

                return (price || 0).toFixed(2) + " €";
            }
        }
    ];


    headers.push({
        id: 'language', label: props.intl.formatMessage({ id: 'DataGridCardList.inCollectionLanguage' }), flex: 1, render: (card) => {

            Language.forEach((l) => { l.disabled = true });

            /* let set = sets.find((e) => e.code == card.setCode);
           
                        if (set && (set.isForeignOnly == undefined || set.isForeignOnly == false) && (card.isAlternative == undefined || card.isAlternative == false)) {
                            Language.find((el) => el.language == "English").disabled = false;
                        }
            */
            card.lang.forEach((el) => {
                Language.find((e) => e.label == el).disabled = false;
            });

            return <Box>
                {Language.map((l, i) => {
                    if (l.disabled) {
                        return <span style={{ paddingLeft: i === 0 ? '0' : '5px' }}>
                            <Button sx={{ fontSize: 12, minWidth: "16px", lineHeight: "initial" }} variant={collection.find((e) => e.card_id == card.id && e.language == l.label) ? "contained" : "outlined"} size="small" disabled={l.disabled} onClick={() => { /*onLanguageClick(card, l)*/ }}>{l.display}</Button>
                        </span>
                    } else {
                        return <span style={{ paddingLeft: i === 0 ? '0' : '5px' }}>
                            <BootstrapTooltip title={l.language || ""} arrow placement="top">
                                <Button sx={{ fontSize: 12, minWidth: "16px", lineHeight: "initial" }} variant={collection.find((e) => e.card_id == card.id && e.language == l.label) ? "contained" : "outlined"} size="small" disabled={l.disabled} onClick={() => { /*onLanguageClick(card, l)*/ }}>{l.display}</Button>
                            </BootstrapTooltip>
                        </span>
                    }
                })}
            </Box>
        }
    });

    async function fetchData() {
        try {
            setDisplayLoader(true);
            let _sets = [];
            let _cards = [];
            for (let cardInCollection of collection) {
                let r = _sets.find((e) => e.code == cardInCollection.set_code)
                if (r == undefined) {
                    r = (await props.dispatch(actions.set.get(cardInCollection.set_code))).set;
                    _sets.push(r);
                }

                let _card = r?.cards?.find((_e) => _e.id == cardInCollection.card_id);
                if (_card) {
                    _cards.push(_card);
                }
            }
            setSets(_sets);
            setCards(_cards);
            setCardsFiltered(_cards)
        } catch (err) {
            props.snackbar.error(err.message);
        } finally {
            setDisplayLoader(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {

        let filteredCard = [];
        cardsUnFiltered.map((card) => {
            for (let key in card.name) {
                let f = card.name[key];
                if (f.toUpperCase().includes(filter.toUpperCase())) {
                    if (filteredCard.find((e) => e.id == card.id) == undefined) {
                        filteredCard.push(card);
                    }
                }
            }
        });

        setCardsFiltered(filteredCard);

    }, [filter]);

    let totalPrice = 0;
    let totalPriceFoil = 0;

    let inCollectionCardCount = 0;
    let inCollectionCardFoilCount = 0;
    let totalCollectionCardCount = 0;
    let totalCollectionCardFoilCount = 0;

    let finalCollection = [];
    collection.map((c) => {
        if (c.isFoil) {
            inCollectionCardFoilCount++;
        } else {
            inCollectionCardCount++;
        }

        if (finalCollection.find((e) => e.id == c.card_id) == undefined) {
            if (c.isFoil) {
                totalCollectionCardFoilCount++;
                let _card = cards.find((_e) => _e.id == c.card_id);
                if (_card?.prices?.eur_foil != undefined) {
                    totalPriceFoil += parseFloat(_card?.prices?.eur_foil.toString());
                }

            } else {
                totalCollectionCardCount++;
                let _card = cards.find((_e) => _e.id == c.card_id);
                if (_card?.prices?.eur != undefined) {
                    totalPrice += parseFloat(_card?.prices?.eur.toString());
                }
            }
            finalCollection.push(c);
        }
    });


    function _normal() {
        return "" + totalCollectionCardCount + " (" + inCollectionCardCount + ") " + " " + props.intl.formatMessage({ id: "DataGridCardList.Cards" }) + " - " + totalPrice.toFixed(2) + "€";
    }
    function _foil() {
        return "" + totalCollectionCardFoilCount + " (" + inCollectionCardFoilCount + ") " + " " + props.intl.formatMessage({ id: "DataGridCardList.Cards" }) + " - " + totalPriceFoil.toFixed(2) + "€";

    }

    return <Box sx={{ display: "grid" }}>

        <Grid container >
            <Grid size={6}>
                <Typography component="div" variant="h5" noWrap>
                    {props.intl.formatMessage({ id: "Header.Collection.Title" }) + " :"}
                </Typography>
            </Grid>
            <Grid size={6} sx={{ textAlign: "right" }}>
                <Typography component="div" variant="h6" noWrap>
                    {_normal()}
                </Typography>
                <Typography component="div" variant="h6" noWrap>
                    {_foil()}
                </Typography>
            </Grid>
        </Grid >
        <br />
        <Grid container >
            <Grid size={2}>
                <CardFilter
                    onChange={(value) => {
                        setFilter(value)
                    }} />
            </Grid>
        </Grid>
        <br />
        <Loader display={displayLoader} />

        {(cards.length > 0) && <DataGrid
            headers={headers}
            rows={cards}
            onCellClick={(event, params, card) => {
                if (params.field == 'name' && cardPrint.visible == false) {
                    setCardPrint({ visible: true, position: { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }, card });
                } else {
                    setCardPrint({ visible: false });
                }
                if (params.field == 'setName') {
                    let set = sets.find((e) => e.code == (collection?.find((c) => c.card_id == card.id))?.set_code);
                    props.navigation.push(Router.urlSet(set.code))
                }
            }}
            onRowDoubleClick={(card) => {
                props.navigation.push(Router.urlCard(card.id));
            }}
            contexMenu={(item, closeContextMenu) => {
                return <CardContextMenu
                    user={props.globalState.user}
                    item={item}
                    onAddCard={(item) => {
                        setAddCard({ visible: true, card: item });
                        closeContextMenu();
                    }}
                    onRemoveCard={(item) => {
                        setRemoveCard({ visible: true, card: item });
                        closeContextMenu();
                    }}
                />
            }}
        />}

        {(cardPrint.visible == true) && <DisplayImage
            position={{ x: cardPrint.position.mouseX, y: cardPrint.position.mouseY }}
            height="300px"
            card={cardPrint.card}
        />}

        {removeCard.visible && <RemoveCarddialog
            title={props.intl.formatMessage({ id: "ContextMenuItem.RemoveFromCollection" }) + " : " + utils.CardLocalise.getTranslationName(removeCard.card, props.globalState.user)}
            card={removeCard.card}
            set={sets.find((e) => e.code == (collection?.find((c) => c.card_id == removeCard.card.id))?.set_code)}
            collection={collection}
            onClose={() => setRemoveCard({ ...removeCard, visible: false })}
            onValid={async (list) => {
                for (let _card of list) {
                    try {
                        let coll = await props.dispatch(actions.collection.remove(_card.id));
                        setRemoveCard({ ...removeCard, visible: false });
                        setCollection(coll.collection);
                    } catch (err) {
                        props.snackbar.error(err.message);
                    }
                }
            }}
            languages={Language}
        />}

        {addCard.visible && <AddCardDialog
            title={props.intl.formatMessage({ id: "ContextMenuItem.AddToCollection" }) + " : " + utils.CardLocalise.getTranslationName(addCard.card, props.globalState.user)}
            card={addCard.card}
            set={sets.find((e) => e.code == (collection?.find((c) => c.card_id == addCard.card.id))?.set_code)}
            collection={collection}
            languages={Language}
            onClose={() => setAddCard({ ...addCard, visible: false })}
            onValid={async (card, langue, isFoil, note) => {
                try {
                    let coll = await props.dispatch(actions.collection.add(sets.find((e) => e.code == (collection?.find((c) => c.card_id == addCard.card.id))?.set_code), card, langue, isFoil, note));
                    setAddCard({ ...addCard, visible: false })
                    setCollection(coll.collection);
                } catch (err) {
                    props.snackbar.error(err.message);
                }
            }}
        />}
    </Box>
}

export default withStoreProvider(withNavigation(withSnackBar(injectIntl(CollectionPage))));