import { injectIntl } from 'react-intl';
import { useEffect, useState, useRef } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import FlareIcon from '@mui/icons-material/Flare';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


function RemoveCardDialog(props) {
    const intl = props.intl;

    const [collection, setCollection] = useState(props.collection.filter((e) => e.card_id == props?.card?.id));

    return <Dialog onClose={() => { props.onClose && props.onClose(); }} open={true} >
        <DialogTitle>{props.title || ""}</DialogTitle>
        <DialogContent dividers>
            {collection?.map((card) => {
                return <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem sx={{ height: "34px" }} dense>
                        <ListItemIcon>
                            <Checkbox onChange={(event=>{
                                card.delete = event.target.checked;
                            })}/>
                        </ListItemIcon>

                        <ListItemText primary={card.note.length > 0 ? card.note : intl.formatMessage({ id: "RemoveCardDialog.NoDescription" })} />

                        <Grid container>
                            <Grid size={6}>
                                {card.isFoil && <Tooltip title={"foil"} arrow placement="top">
                                    <FlareIcon />
                                </Tooltip>}
                            </Grid>
                            <Grid size={6}>
                                <Tooltip title={(props.languages.find((e) => e.label == card.language))?.language || ""} arrow placement="top">
                                    <Button sx={{ fontSize: 12, minWidth: "48px", lineHeight: "initial" }} variant={"contained"} size="small" onClick={() => { }}>{(props.languages.find((e) => e.label == card.language))?.display || ""}</Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            })}
        </DialogContent>
        <DialogActions dividers sx={{ display: "block" }}>
            <Grid container spacing={2} >
                <Grid size={6} sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            props.onValid && props.onValid(collection.filter((e) => e.delete == true));
                        }}
                    >{intl.formatMessage({ id: 'Button.validate' })}</Button>
                </Grid>
                <Grid size={6} sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            props.onClose && props.onClose();
                        }}
                    >{intl.formatMessage({ id: 'Button.cancel' })}</Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>

}
export default injectIntl(RemoveCardDialog);