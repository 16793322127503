import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';

import Box from '@mui/material/Box';
import Loader from '../../components/loader';
import DataGrid from '../../components/datagrid';


import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import actions from '../../actions';
import utils from '../../utils';

import MtgSvg from '../../components/mtgSvg';
import Router from '../../routes';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

function SearchPage(props) {

    const { word } = useParams();

    const [displayLoader, setDisplayLoader] = useState(true);
    const [cards, setCards] = useState([]);
    const [collection, setCollection] = useState(props.globalState?.collection || []);
    const [sets, setSets] = useState([]);
    const [actualWord, setActualWord] = useState(word);

    const intl = props.intl;

    async function fetchData() {
        setDisplayLoader(true);
        let result = (await props.dispatch(actions.search.byWord(actualWord)));
        let _sets = [];
        for (let _cards of result.search) {
            let r = _sets.find((e) => e.code == _cards.set_code);
            if (r == undefined) {
                r = (await props.dispatch(actions.set.get(_cards.set_code))).set;
                _sets.push(r);
            }
        }

        setSets(_sets);
        setCards(result.search);
        setDisplayLoader(false);

    }
    useEffect(() => {
        fetchData();
    }, [actualWord]);

    if (actualWord != word){
        setActualWord(word);
    }
    async function onLanguageClick(card, language) {
        try {
            let set = sets.find((e) => e.code == card.set_code);
            if (set.foil_only == true) {
                let result = await props.dispatch(actions.collection.addFoil(set, card, language.label, ""));
                setCollection(result.collection);
            } else {
                let result = await props.dispatch(actions.collection.addNormal(set, card, language.label, ""));
                setCollection(result.collection);
            }
        } catch (err) {
            props.snackbar.error(err.message);
        } finally {

        }
    }

    let Language = [
        { label: "en", display: "en", language: "English", disabled: true },
        { label: "es", display: "es", language: "Spanish", disabled: true },
        { label: "fr", display: "fr", language: "French", disabled: true },
        { label: "de", display: "de", language: "German", disabled: true },
        { label: "it", display: "it", language: "Italian", disabled: true },
        { label: "pt", display: "pt", language: "Portuguese (Brazil)", disabled: true },
        { label: "ja", display: "ja", language: "Japanese", disabled: true },
        { label: "ko", display: "ko", language: "Korean", disabled: true },
        { label: "ru", display: "ru", language: "Russian", disabled: true },
        { label: "zhs", display: "汉语", language: "Chinese Simplified", disabled: true },
        { label: "zht", display: "漢語", language: "Chinese Traditional", disabled: true }
    ];

    const headers = [
        {
            id: 'collector_number', label: props.intl.formatMessage({ id: "DataGridCardList.Number" }), width: 75, render: ((card) => {
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {card.collector_number}
                </Box>
            })
        },
        {
            id: 'name', label: props.intl.formatMessage({ id: "DataGridCardList.Name" }), flex: 1, render: ((card) => {
                return utils.CardLocalise.getTranslationName(card, props.globalState.user)
            })
        },
        {
            id: 'mana_cost', label: props.intl.formatMessage({ id: "DataGridCardList.Color" }), minWidth: 100, render: ((card) => {

                let manaCost = card?.mana_cost?.replaceAll('{', '').replaceAll('/', '').split('}').filter(e => e.length > 0);
                return <Box sx={{ paddingTop: "4px" }}>
                    {manaCost?.map((mc) => {
                        return <MtgSvg
                            svgName={"svg_" + mc}
                            width="18px" />
                    })}
                </Box>
            })
        },
        {
            id: 'setName', label: props.intl.formatMessage({ id: "Header.Set.Title" }), minWidth: 150, render: ((card) => {
                let set = sets.find((e) => e.code == card.set_code);
                return <Tooltip title={utils.ExpansionLocalise.getTranslationName(set, props.globalState.user)}>
                <div style={{ cursor: "pointer" }}>{utils.ExpansionLocalise.getTranslationName(set, props.globalState.user)}</div>
            </Tooltip>
            })
        },
        {
            id: 'rarity', label: props.intl.formatMessage({ id: "DataGridCardList.Rarity" }), width: 64, render: ((card) => {
                let set = sets.find((e) => e.code == card.set_code);
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center", paddingTop: "4px" }}>
                    <MtgSvg
                        svgName={"svg_" + set.code}
                        class={"rarity_" + card.rarity}
                        height="24px" />
                </Box>
            })
        },
        {
            id: 'count', label: props.intl.formatMessage({ id: "DataGridCardList.Collection" }), render: (card) => {
                let count = collection.filter((e) => ((e.card_id == card.id) && (e.isFoil == false)));
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {count.length > 0 ? count.length : ''}
                </Box>
            }
        },
        {
            id: 'price', label: props.intl.formatMessage({ id: "DataGridCardList.AveragePrice" }), render: (card) => {
                let price = 0;
                if (card?.prices?.eur != undefined) {
                    price += parseFloat(card?.prices?.eur.toString());
                }
                return <Box sx={{ width: "100%", height: "100%", textAlign: "center" }}>
                    {(price || 0).toFixed(2) + " €"}
                </Box>

            }
        },
        {
            id: 'foilCount', label: props.intl.formatMessage({ id: "DataGridCardList.Foils" }), render: (card) => {
                let count = collection.filter((e) => ((e.uuid == card.uuid) && (e.isFoil == true)));
                return count.length > 0 ? count.length : '';
            }
        },
        {
            id: 'foilPrice', label: props.intl.formatMessage({ id: "DataGridCardList.AverageFoilPrice" }), render: (card) => {
                let price = 0;
                if (card?.prices?.eur_foil != undefined) {
                    price += parseFloat(card?.prices?.eur_foil.toString());
                }

                return (price || 0).toFixed(2) + " €";
            }
        }
    ];


    headers.push({
        id: 'language', label: props.intl.formatMessage({ id: 'DataGridCardList.inCollectionLanguage' }), flex: 1, render: (card) => {

            Language.forEach((l) => { l.disabled = true });

            let set = sets.find((e) => e.code == card.setCode);
            /*
                        if (set && (set.isForeignOnly == undefined || set.isForeignOnly == false) && (card.isAlternative == undefined || card.isAlternative == false)) {
                            Language.find((el) => el.language == "English").disabled = false;
                        }
            */
            card.lang.forEach((el) => {
                Language.find((e) => e.label == el).disabled = false;
            });

            return <Box>
                {Language.map((l, i) => {
                    return <span style={{ paddingLeft: i === 0 ? '0' : '5px' }}>
                        <BootstrapTooltip title={l.language || ""} arrow placement="top">
                            <Button sx={{ fontSize: 12, minWidth: "16px", lineHeight: "initial" }} variant={collection.find((e) => e.card_id == card.id && e.language == l.label) ? "contained" : "outlined"} size="small" disabled={l.disabled} onClick={() => { onLanguageClick(card, l) }}>{l.display}</Button>
                        </BootstrapTooltip>
                    </span>
                })}
            </Box>
        }
    });


    return <Box>

        <Loader display={displayLoader} />

        {(cards.length > 0) && <DataGrid
            headers={headers}
            rows={cards}
            onCellClick={(event, params, card) => {
                /*if (params.field == 'name' && cardPrint.visible == false) {
                    setCardPrint({ visible: true, position: { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }, card });
                } else {
                    setCardPrint({ visible: false });
                }*/
                if (params.field == 'setName') {
                    let set = sets.find((e) => e.code == card?.set_code);
                    props.navigation.push(Router.urlSet(set.code))
                }
            }}
        />}
    </Box>
}

export default withStoreProvider(withNavigation(withSnackBar(injectIntl(SearchPage))));