import { useEffect, useState, forwardRef, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';

import Loader from '../../components/loader';
import SetCard from '../../components/setCard';
import Box from '@mui/material/Box';

import actions from '../../actions';
import Router from '../../routes';

import { VirtuosoGrid } from 'react-virtuoso';

function BlocksPage(props) {

    const elementRef = useRef(null);
    const [displayLoader, setDisplayLoader] = useState(false);
    const [sets, setSets] = useState([]);

    async function fetchData() {
        try {
            setDisplayLoader(true);
            let result = await props.dispatch(actions.sets.all());
            if (result.sets) {
                setSets(result.sets);
            }
        } catch (err) {
            props.snackbar.error(err.message);
        } finally {
            setDisplayLoader(false);
        }
    }

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const gridComponents = {
        List: forwardRef(({ style, children, ...props }, ref) => {
            return <div
                ref={ref}
                {...props}
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    ...style,
                }}
            >
                {children}
            </div>
        }),
        Item: ({ children, ...props }) => {
            return <div
                {...props}
                style={{
                    padding: "0.5rem",
                    width: "25%",
                    display: "flex",
                    flex: "none",
                    alignContent: "stretch",
                    boxSizing: "border-box",
                }}
            >
                {children}
            </div>
        }
    }

    return <Box ref={elementRef}>

        <Loader display={displayLoader} />
        <Box>
            {(sets.length > 0) && <VirtuosoGrid
                useWindowScroll
                totalCount={sets.length}
                components={gridComponents}
                itemContent={(index) => {
                    return <SetCard
                        expansion={sets[index]}
                        collection={props.globalState.collection.filter((e) => e.set_code === sets[index].code)}
                        onClick={() => {
                            props.navigation.push(Router.urlSet(sets[index].code))
                        }}
                    />
                }}
            />}
        </Box>
    </Box>;
}


export default withStoreProvider(withNavigation(withSnackBar(injectIntl(BlocksPage))));