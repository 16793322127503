import MenuItem from '@mui/material/MenuItem';
import { injectIntl } from 'react-intl';

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

import Divider from '@mui/material/Divider';

import utils from '../../utils';

function CardContextMenu(props) {

    const item = props.item || {};

    return <>
        <MenuItem disabled >
            <ListItemText >{utils.CardLocalise.getTranslationName(item, props.user)}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => {
            props.onAddCard && props.onAddCard(item);
        }}>
            <ListItemIcon>
                <BookmarkAddIcon />
            </ListItemIcon >
            <ListItemText >
                {props.intl.formatMessage({ id: "ContextMenuItem.AddToCollection" })}
            </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
            props.onRemoveCard && props.onRemoveCard(item);
        }}>
            <ListItemIcon>
                <BookmarkRemoveIcon />
            </ListItemIcon >
            <ListItemText >
                {props.intl.formatMessage({ id: "ContextMenuItem.RemoveFromCollection" })}
            </ListItemText>
        </MenuItem>
        <MenuItem disabled={item?.purchase_uris?.cardmarket == undefined} onClick={() => {
            window.open(item?.purchase_uris?.cardmarket, '_blank');
        }}>
            <ListItemIcon>
                <ShoppingCartCheckoutIcon />
            </ListItemIcon >
            <ListItemText >
                {props.intl.formatMessage({ id: "ContextMenuItem.CardMarketShop" })}
            </ListItemText>
        </MenuItem>
    </>
}

export default injectIntl(CardContextMenu);